import { useLocation } from "react-router-dom";
import Router from "./routes";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import * as AgGrid from "ag-grid-enterprise";
import {
  AmplitudeInit,
  handleClickForTracking,
  handlePageLoadForTracking,
} from "./analytics";
import { useAuthStore } from "./store/useAuthStore";
import { useEffect } from "react";
import { QueryClientProvider } from "react-query";
import ScrollToTop from "./shared/ScrollToTop";
import { ToastContainer } from "react-toastify";
import { queryClient } from "./queries/client";
import { initGA, logPageView } from "./GAanalytics";

const agGridLicenseKey = process.env.REACT_APP_AG_LICENSE_KEY || "";
AgGrid.LicenseManager.setLicenseKey(agGridLicenseKey);

function App() {
  AmplitudeInit();
  useAuthStore();
  const location = useLocation();

  
  useEffect(() => {
    if (process.env.REACT_APP_GA4) {
      initGA(process.env.REACT_APP_GA4 || "");
      logPageView(window.location.pathname + window.location.search);
    }
    handlePageLoadForTracking("location");
  }, [location]);
  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop />
      <div
        onClick={(e) => {
          handleClickForTracking(e);
        }}
      >
        <Router />
      </div>
      <ToastContainer position="bottom-right" autoClose={2000} />
    </QueryClientProvider>
  );
}

export default App;
