import ReactGA from "react-ga4";

export const initGA = (trackingId: string) => {
  ReactGA.initialize(trackingId);
};

export const logPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const logGAEvent = (
  category: string,
  action: string,
  label?: string
) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
