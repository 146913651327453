import React, { useState, useMemo, useCallback } from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';

interface Company {
  name: string;
  id: string;
  valuation: number;
  companyCurrency: string;
  jurisdiction: string;
  valuationDate: string;
  dba: string;
  cin: string;
  companyStage: string;
  isPrivate: boolean;
}

interface ComboboxInputProps {
  value: string;
  onChange: (value: string) => void;
  options: Company[];
}

const ComboboxInput: React.FC<ComboboxInputProps> = ({ value, onChange, options }) => {
  const [query, setQuery] = useState('');
  const [visibleCount, setVisibleCount] = useState(20);

  // Memoize filtered options to prevent recalculation on every render
  const filteredOptions = useMemo(() => {
    if (query === '') return options;
    
    const normalizedQuery = query.toLowerCase().replace(/\s+/g, '');
    return options.filter((option) => {
      const normalizedName = (option?.name ?? '').toLowerCase().replace(/\s+/g, '');
      return normalizedName.includes(normalizedQuery);
    });
  }, [query, options]);

  // Get only the visible portion of filtered options
  const visibleOptions = useMemo(() => {
    return filteredOptions.slice(0, visibleCount);
  }, [filteredOptions, visibleCount]);

  // Handle scroll event to load more items
  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const element = event.currentTarget;
    if (
      element.scrollHeight - element.scrollTop <= element.clientHeight + 50 && 
      visibleCount < filteredOptions.length
    ) {
      setVisibleCount(prev => Math.min(prev + 20, filteredOptions.length));
    }
  }, [filteredOptions.length, visibleCount]);

  // Memoize the selection handler
  const handleSelect = useCallback((selected: any) => {
    if (typeof selected === 'string') {
      onChange(selected);
    } else {
      onChange(selected?.name ?? "");
    }
  }, [onChange]);

  // Reset visible count when query changes
  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setQuery(newValue);
    setVisibleCount(20); // Reset to initial count
    onChange(newValue);
  }, [onChange]);

  // Memoize the option renderer
  const OptionComponent = useCallback(({ option, active, selected }: {
    option: Company;
    active: boolean;
    selected: boolean;
  }) => (
    <>
      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
        {option.name}
      </span>
      {selected && (
        <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
          active ? 'text-white' : 'text-indigo-600'
        }`}>
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      )}
    </>
  ), []);

  return (
    <Combobox value={value} onChange={handleSelect}>
      <div className="relative mt-1">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
          <Combobox.Input
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
            onChange={handleInputChange}
            displayValue={(item: string) => item}
            value={value}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Combobox.Options 
          className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
          onScroll={handleScroll}
        >
          {visibleOptions.map((option) => (
            <Combobox.Option
              key={option.id}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                }`
              }
              value={option}
            >
              {({ active, selected }) => (
                <OptionComponent 
                  option={option}
                  active={active}
                  selected={selected}
                />
              )}
            </Combobox.Option>
          ))}
          {visibleCount < filteredOptions.length && (
            <div className="py-2 px-4 text-sm text-gray-500 text-center">
              Scroll for more options
            </div>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

export default ComboboxInput;