import React from "react";
import { formatDisplayDate } from "../../utils/date";
import { taskstates } from "../myTask/IssueState";
import { CustomSelect, StatesModel } from "../myTask/UserStateChange";
import { HStack } from "../../shared/utils";
import { getDates } from "../aggrid/AgComponent";
import { Icon } from "@iconify/react";
import { handleClickForTracking } from "../../analytics";
import { AnalyticsConstant } from "../../AnalyticsContant";

interface Task {
  id: string;
  states: StatesModel[];
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  status: string;
  priority: string;
  isActive: boolean;
}

interface TaskCardProps {
  task: Task;
  setShowTaskOnCalender: (id: string) => void;
}

const TaskCard: React.FC<TaskCardProps> = ({ task, setShowTaskOnCalender }) => {
  const {
    name,
    description,
    startDate,
    endDate,
    status,
    priority,
    isActive,
    states,
  } = task;
  const getStatusColor = (status: string): string => {
    const data = taskstates.find((ele) => ele.name === status);
    return data?.color + " " + data?.textColor;
  };

  const getPriorityColor = (priority: string): string => {
    switch (priority.toLowerCase()) {
      case "high":
        return "text-red-600";
      case "medium":
        return "text-yellow-600";
      case "low":
        return "text-green-600";
      default:
        return "text-gray-600";
    }
  };
  const border = taskstates.find((ele) => ele.key === status)?.borderColor;

  return (
    <div
      onClick={() => {
        handleClickForTracking(AnalyticsConstant.calender_task_card_clicked);
        setShowTaskOnCalender(task.id);
      }}
      className={`flex cursor-pointer flex-col px-4 py-2 bg-white rounded-lg shadow-md border-l-4 ${border}`}
    >
      <div className="flex justify-between items-center mb-2">
        <h3 className="font-semibold text-lg">{name}</h3>

        <CustomSelect
          state={states ?? []}
          isAssignee={true}
          isPreview={true}
          data={status ?? ""}
          onChange={(data: any) => {}}
        />
      </div>

      <div className="flex justify-between text-xs">
        <span className="text-gray-500">{`${formatDisplayDate(startDate)
          .split(" ")
          .slice(0, 2)
          .join(" ")} - ${formatDisplayDate(endDate)
          .split(" ")
          .slice(0, 2)
          .join(" ")}`}</span>
        <HStack className="items-center gap-1">
          <span className={`font-medium ${getPriorityColor(priority)}`}>
            {priority}
          </span>

          {status.toLowerCase() !== "closed" && getDates(endDate) !== null && (
            <span
              className={`inline-flex whitespace-nowrap w-fit items-center gap-x-1.5 rounded-md  px-2 py-0.5 text-[10px] leading-0 font-medium ${
                (getDates(endDate) ?? 0) > 0
                  ? "text-yellow-600 bg-yellow-50"
                  : "text-red-600 bg-red-50"
              }`}
            >
              <Icon
                icon={"lsicon:overtime-filled"}
                className="text-red-500 w-3 h-3"
              />
              {(getDates(task.endDate) ?? 0) > 0
                ? `${getDates(task.endDate)} Days Left`
                : (getDates(task.endDate) ?? 0) === 0
                ? `Due Today`
                : `${Math.abs(getDates(task.endDate) ?? 0)} d Overdue`}
            </span>
          )}
        </HStack>
      </div>
    </div>
  );
};

export default TaskCard;
