import React, { useState, useEffect, useRef } from "react";
import { Bell, Download, Calendar, Clock, X, Loader2 } from "lucide-react";
import { useGetNotifications } from "../../queries/admin";
import { useAuthStore } from "../../store/useAuthStore";
import { queryClient } from "../../queries/client";
import { limitString } from "../../utils/string";
import { handleClickForTracking } from "../../analytics";
import { AnalyticsConstant } from "../../AnalyticsContant";

interface NotificationEventData {
  fileUrl: string;
  fileName: string;
  fileSize: number;
}

interface Notification {
  id: string;
  eventData: NotificationEventData;
  userId: string;
  eventType: string;
  eventLevel: string;
  timestamp: string;
  message: string;
  expiry: string;
}

const ProjectDownlinkComponent: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [newNotifications, setNewNotifications] = useState(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadingIds, setDownloadingIds] = useState<string[]>([]);
  const popoverRef = useRef<HTMLDivElement>(null);

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const formatTimeAgo = (timestamp: string) => {
    const date = new Date(timestamp);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (seconds < 60) return `${seconds} seconds ago`;
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    const days = Math.floor(hours / 24);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  };

  const handleDownload = async (notification: Notification) => {
    try {
      setDownloadingIds((prev) => [...prev, notification.id]);
      const { fileUrl, fileName } = notification.eventData;

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
    } finally {
      setDownloadingIds((prev) => prev.filter((id) => id !== notification.id));
    }
  };

  const removeNotification = (id: string) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    );
    if (notifications.length === 1) {
      setNewNotifications(0);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { data } = useGetNotifications();

  useEffect(() => {
    if (data) setNotifications(data.data);
  }, [data]);
  const { accessToken } = useAuthStore();
  useEffect(() => {
    const eventSource = new EventSource(
      `${
        process.env.REACT_APP_API || "http://localhost:3000"
      }events?AccessToken=${accessToken}`
    );

    eventSource.addEventListener("DOWNLOAD", (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);
        handleNotification(data);
      } catch (e) {}
    });

    const handleNotification = (data: any) => {
      if (data) {
        setNewNotifications((prev) => prev + 1);
        queryClient.invalidateQueries("getNotifications");
      }
    };

    eventSource.onerror = (error) => {
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const handlePopoverToggle = () => {
    setIsPopoverOpen(!isPopoverOpen);
    if (!isPopoverOpen) {
      setNewNotifications(0);
    }
  };

  return (
    <div className="relative">
      <div ref={popoverRef}>
        <button
          className="relative p-3 bg-white rounded-full hover:bg-gray-50 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={handlePopoverToggle}
          aria-label="Toggle notifications"
        >
          <Bell className="h-5 w-5 text-gray-600" />
          {newNotifications > 0 && (
            <span className="absolute -top-1 -right-1 flex items-center justify-center w-5 h-5 text-xs font-medium text-white bg-blue-600 rounded-full animate-pulse">
              {newNotifications}
            </span>
          )}
        </button>

        {isPopoverOpen && (
          <div className="absolute right-0 mt-3 sm:w-96 w-60 bg-white rounded-xl shadow-xl border border-gray-100 transform transition-all duration-200 z-50">
            <div className="px-4 py-3 border-b border-gray-100">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-800">
                  Downloads
                </h3>
                <button
                  onClick={() => {
                    handleClickForTracking(
                      AnalyticsConstant.sse_close_icon_clicked
                    );
                    setIsPopoverOpen(false);
                  }}
                  className="p-1 rounded-full hover:bg-gray-100 text-gray-500 transition-colors"
                  aria-label="Close notifications"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            </div>

            <div className="max-h-96 overflow-y-auto">
              {isLoading ? (
                <div className="p-8 text-center">
                  <Loader2 className="h-8 w-8 text-blue-500 animate-spin mx-auto" />
                  <p className="mt-2 text-gray-500">Loading downloads...</p>
                </div>
              ) : notifications.length === 0 ? (
                <div className="p-8 text-center">
                  <Bell className="h-12 w-12 text-gray-300 mx-auto mb-3" />
                  <p className="text-gray-500">No downloads available</p>
                </div>
              ) : (
                <ul className="p-2 space-y-2">
                  {notifications
                    .sort((a, b) => {
                      return Date.parse(b.timestamp) - Date.parse(a.timestamp);
                    })
                    .map((notification) => (
                      <li
                        key={notification.id}
                        className="group relative p-4 hover:bg-gray-50 rounded-lg transition-colors duration-200"
                      >
                        <div className="flex items-start justify-between">
                          <div className="flex-1 min-w-0 pr-4">
                            <p className="text-sm font-medium text-gray-900 truncate">
                              {limitString(notification.eventData.fileName, 20)}
                            </p>
                            <p className="text-sm text-gray-500">
                              {formatFileSize(notification.eventData.fileSize)}
                            </p>

                            <div className="flex items-center space-x-3 mt-2 text-xs text-gray-500">
                              <span className="flex items-center">
                                <Calendar className="h-3 w-3 mr-1" />
                                {formatTimeAgo(notification.timestamp)}
                              </span>
                            </div>

                            <p className="mt-1 text-xs text-red-500">
                              Expires:{" "}
                              {new Date(notification.expiry).toLocaleString()}
                            </p>
                          </div>

                          <div className="flex items-center space-x-1">
                            <button
                              onClick={() => {
                                handleClickForTracking(
                                  AnalyticsConstant.sse_download_clicked
                                );
                                handleDownload(notification);
                              }}
                              disabled={downloadingIds.includes(
                                notification.id
                              )}
                              className={`p-2 rounded-full transition-colors ${
                                downloadingIds.includes(notification.id)
                                  ? "text-gray-400"
                                  : "text-blue-600 hover:bg-blue-50"
                              }`}
                              title="Download file"
                            >
                              {downloadingIds.includes(notification.id) ? (
                                <Loader2 className="h-4 w-4 animate-spin" />
                              ) : (
                                <Download className="h-4 w-4" />
                              )}
                            </button>
                            <button
                              onClick={() => {
                                handleClickForTracking(
                                  AnalyticsConstant.sse_remove_icon_clicked
                                );
                                removeNotification(notification.id);
                              }}
                              className="sm:block hidden p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full opacity-0 group-hover:opacity-100 transition-all"
                              title="Remove notification"
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDownlinkComponent;
