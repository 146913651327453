import React, { useState, useEffect } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function MultiSelectFilterTab({
  key,
  selected,
  tabs,
  onChange,
}: {
  key: string;
  selected: any[];
  tabs: any;
  onChange: any;
}) {
  const [selectedTabs, setSelectedTabs] = useState<any>(["ALL"]);

  useEffect(() => {
    setSelectedTabs(["ALL"]);
  }, [key]);
  const toggleTab = (tab: any) => {
    setSelectedTabs((prev: any) =>
      prev.includes(tab) ? prev.filter((t: any) => t !== tab) : [...prev, tab]
    );
    if (tab !== "ALL") {
      setSelectedTabs((prev: any) => prev.filter((t: any) => t !== "ALL"));
    }
    if (tab === "ALL" && selectedTabs.length > 1) {
      setSelectedTabs(["ALL"]);
    }
  };

  useEffect(() => {
    onChange(selectedTabs);
  }, [selectedTabs]);

  return (
    <div className="flex justify-end">
      <nav aria-label="Tabs" className="inline-flex" role="group">
        {["ALL", ...tabs].map((tab: any, index: any) => (
          <button
            onClick={() => toggleTab(tab)}
            key={tab}
            className={classNames(
              "px-4 py-2 text-xs font-semibold border border-gray-300",
              "transition-all duration-200 border-b-0 ease-in-out",
              "",
              selectedTabs.includes(tab)
                ? "border border-b-indigo-600 text-indigo-600 bg-gray-100 font-semibold"
                : "bg-white text-gray-700 hover:bg-gray-100",
              " shadow-box",
              index === 0 ? "rounded-tl-md" : "",
              index === tabs.length - 1 ? "rounded-tr-md" : "",
              index !== 0 ? "-ml-px" : ""
            )}
          >
            {`${tab}`}
          </button>
        ))}
      </nav>
    </div>
  );
}
