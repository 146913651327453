import { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, VStack } from "../shared/utils";
import { toast } from "react-toastify";
import { Mail, X } from "lucide-react";
import { queryClient } from "../queries/client";
import { useNavigate } from "react-router-dom";
import { useSendReminder } from "../queries/admin";
import SearchAutoCompleteDropDown, {
  SwitchDropDownModel,
} from "../pages/myTask/SearchInputBox";
import { UserProjectRoles } from "../api/userRole";
import { LabelText } from "../pages/myTask/SideBarPanel";
import { useAuthStore } from "../store/useAuthStore";
import { useGetUserTaskRoles } from "../queries/userRole";
import { EventButton } from "../shared/CTAButtonComponents";
import { handleClickForTracking } from "../analytics";
import { AnalyticsConstant } from "../AnalyticsContant";

const UpdateSubTaskModal = ({
  taskIdData,
  taskData,
  isOpen,
  onClose,
  onComplete,
}: {
  taskIdData: any;
  taskData: any;
  isOpen: any;
  onClose: any;
  onComplete: (update: boolean) => void;
}) => {
  const handleAccept = () => {
    handleClickForTracking(
      AnalyticsConstant.update_group_task_override_button_modal
    );
    onComplete(true);
    onClose();
  };
  const handleReject = () => {
    handleClickForTracking(
      AnalyticsConstant.update_group_task_continue_withoutoverride_button_modal
    );
    onComplete(false);
    onClose();
  };
  const handleDiscard = () => {
    handleClickForTracking(
      AnalyticsConstant.update_group_task_discard_button_modal
    );
    onClose();
  };

  const isAdmin = useAuthStore.getState().role === "ADMIN";

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <VStack className="items-start">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        onClose();
                        handleClickForTracking(
                          AnalyticsConstant.update_group_task_close_icon_button_modal
                        );
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <HStack className="items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <Mail
                        className="h-6 w-6 text-blue-500"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-base pb-2 pl-2 pt-4 tracking-wide font-semibold leading-6 text-gray-900"
                    >
                      Update Details
                      <p className="text-gray-500 pb-3 tracking-normal font-medium text-xs"></p>
                    </Dialog.Title>
                  </HStack>
                  <VStack>
                    <p className="px-2 pt-2 text-sm">
                      Updating this event will modify the details of all
                      associated sub-tasks. Are you sure you want to proceed
                    </p>
                  </VStack>
                </VStack>
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center pt-8 sm:ml-4 w-full sm:mt-0 sm:text-left">
                 
                    <HStack className="justify-between">
                      <button
                        type="button"
                        className="flex items-center justify-center px-4 py-2 w-full rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => handleDiscard()}
                      >
                        Discard
                      </button>

                      <HStack className="gap-4">
                        <button
                          type="button"
                          className="flex items-center justify-center px-4 py-2 w-full rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => handleReject()}
                        >
                          Continue without Override
                        </button>
                        <EventButton
                          disabled={false}
                          text={"Override"}
                          onClick={() => handleAccept()}
                        />
                      </HStack>
                    </HStack>
                  
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdateSubTaskModal;
