import { useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XMarkIcon, FolderPlusIcon } from "@heroicons/react/24/outline";
import { Input, TextArea } from "../shared/InputField";
import { HStack } from "../shared/utils";
import {
  useCreateProject,
  useGetCompanies,
  useUpdateProject,
} from "../queries/admin";
import { toast } from "react-toastify";
import { queryClient } from "../queries/client";
import { useNavigate } from "react-router-dom";
import { downloadS3File } from "../shared/DownloadFile";
import { useAuthStore } from "../store/useAuthStore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleClickForTracking } from "../analytics";
import { AnalyticsConstant } from "../AnalyticsContant";
import { Select } from "../shared/Select";
import ComboboxInput from "../pages/project/ComboInputBox";
import { Icon } from "@iconify/react";

enum ProjectType {
  PROJECT = "PROJECT",
  DEAL_FLOW = "DEAL_FLOW",
}
const projectType = [
  {
    label: "Project",
    value: ProjectType.PROJECT,
  },
  {
    label: "Deal Flow",
    value: ProjectType.DEAL_FLOW,
  },
];

const CreateProjectModal = ({
  project,
  isOpen,
  onClose,
  onCreateProject,
}: {
  project?: any;
  isOpen: any;
  onClose: any;
  onCreateProject: any;
}) => {
  const { mutate: createProject } = useCreateProject();
  const [loading, setLoading] = useState(false);
  const { mutate: updateProject } = useUpdateProject();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: project
      ? {
          name: project?.name ?? "",
          description: project?.description ?? "",
          companyName: project?.companyName ?? "",
          projectType: project?.companyName ?? "",
          startDate: project?.startDate ? project?.startDate.split("T")[0] : "",
          endDate: project?.endDate ? project?.endDate.split("T")[0] : "",
        }
      : {
          name: "",
          description: "",
          companyName: "",
          projectType: "",
          startDate: "",
          endDate: "",
        },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      projectType: Yup.string().required("Project Type is required"),
      description: Yup.string().required("Description is required"),
      startDate: Yup.date().required("Start Date is required"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const companyFound = companies.find(
        (ele: any) => ele.name === values.companyName
      );
      const companyId = companyFound?.id ?? "";
      handleClickForTracking(
        AnalyticsConstant.create_project_create_project_button_modal
      );
      setLoading(true);
      setLoading(false);
      project
        ? updateProject(
            {
              name: values.name,
              description: values.description,
              projectId: project.id,
              companyId: companyId,
              projectType: values.projectType,
              companyName: values.companyName,
              startDate: new Date(values.startDate).toISOString() ?? "",
              endDate: values.endDate
                ? new Date(values.endDate).toISOString() ?? ""
                : "",
            },
            {
              onSuccess: (data) => {
                setLoading(false);
                toast("Project Updated Successfully", {
                  type: "success",
                  autoClose: 2000,
                });
                queryClient.invalidateQueries("getAllProjects");
                queryClient.refetchQueries("getAllProjects");
                queryClient.invalidateQueries("overview");
                queryClient.refetchQueries("overview");
                onCreateProject();
                formik.resetForm({
                  values: {
                    name: "",
                    projectType: "",
                    description: "",
                    companyName: "",
                    startDate: "",
                    endDate: "",
                  },
                });
                navigate(`/project/${data.data.id}`);
              },
              onError: (err: any) => {
                formik.resetForm();
                toast(err.response.data.message, {
                  type: "error",
                  autoClose: 2000,
                });
                onClose();
              },
            }
          )
        : createProject(
            {
              name: values.name,
              description: values.description,
              companyId: companyId,
              companyName: values.companyName,
              projectType: values.projectType,
              startDate: new Date(values.startDate).toISOString() ?? "",
              endDate: values.endDate
                ? new Date(values.endDate).toISOString() ?? ""
                : "",
            },
            {
              onSuccess: (data) => {
                setLoading(false);
                toast("Project Created Successfully", {
                  type: "success",
                  autoClose: 2000,
                });

                formik.resetForm({
                  values: {
                    name: "",
                    description: "",
                    projectType: "",
                    companyName: "",
                    startDate: "",
                    endDate: "",
                  },
                });
                queryClient.invalidateQueries("getAllProjects");
                queryClient.refetchQueries("getAllProjects");
                queryClient.invalidateQueries("overview");
                queryClient.refetchQueries("overview");
                onCreateProject();
                navigate(`/project/${data.data.id}`);
              },
              onError: (err: any) => {
                formik.resetForm();

                toast(err.response.data.message, {
                  type: "error",
                  autoClose: 2000,
                });
                onClose();
              },
            }
          );
    },
  });

  const [showExcel, setShowExcel] = useState(project ? false : true);
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { accessToken } = useAuthStore();

  const handleAddFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!event?.target?.files?.[0]) return;
    const fileData = event.target.files[0];
    const allowedFileExtensions = [".xlsm", ".xlsx"];
    if (
      fileData &&
      allowedFileExtensions.some((extension) =>
        fileData.name.endsWith(extension)
      )
    ) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", fileData);

      try {
        const baseUrl =
          process.env.REACT_APP_API ||
          "https://dev-taskmanager-api.hissa.com/v1/";
        const response = await fetch(`${baseUrl}/project/import`, {
          method: "POST",
          headers: {
            AccessToken: `${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          const result = await response.json();

          toast(result.message, {
            type: "error",
            autoClose: 2000,
          });
          throw new Error(result.message);
        }
        toast("Project Created Successfully", {
          type: "success",
          autoClose: 2000,
        });
        queryClient.invalidateQueries("getAllProjects");
        queryClient.invalidateQueries("overview");
        onCreateProject();

        const result = await response.json();
        setLoading(false);
        navigate(`/project/${result.data.id}`);
      } catch (error: any) {
        setLoading(false);
        toast(error, {
          type: "error",
          autoClose: 2000,
        });
      }
    }
  };

  const { data: companies } = useGetCompanies("");
  console.log("NIKHIL2", companies);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <HStack className="items-center">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        handleClickForTracking(
                          AnalyticsConstant.create_project_close_icon_modal
                        );
                        onClose();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <HStack className="items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FolderPlusIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-base pb-2 pl-2 font-semibold leading-6 text-gray-900"
                    >
                      {project ? "Update Project" : "Create Project"}
                    </Dialog.Title>
                  </HStack>
                </HStack>
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:ml-4 w-full sm:mt-0 sm:text-left">
                    {showExcel && (
                      <div className="relative border w-full py-4 px-4 border-dashed border-green-600 bg-green-50 rounded-xl mt-4">
                        <XMarkIcon
                          onClick={() => {
                            handleClickForTracking(
                              AnalyticsConstant.create_project_upload_close_icon_modal
                            );
                            setShowExcel(false);
                          }}
                          className="absolute -top-2 -right-2 p-1 w-5 h-5 bg-green-200 text-secondary font-bold cursor-pointer rounded-full border"
                        />
                        <p className="text-green-600">
                          Setup project using excel template{" "}
                          <span
                            className="underline text-indigo-700 cursor-pointer"
                            onClick={() => {
                              handleClickForTracking(
                                AnalyticsConstant.create_project_download_template_modal
                              );
                              downloadS3File(
                                "https://equion-dev.s3.us-west-2.amazonaws.com/task-manager-template-file.xlsx"
                              );
                            }}
                          >
                            download here
                          </span>
                        </p>
                        <HStack className="justify-start mt-4">
                          <input
                            ref={fileRef}
                            onChange={handleAddFile}
                            multiple={false}
                            type="file"
                            accept=".xlsx, .xlsm"
                            hidden
                          />
                          <button
                            onClick={() => {
                              handleClickForTracking(
                                AnalyticsConstant.create_project_upload_button_modal
                              );
                              fileRef.current.click();
                            }}
                            className="px-2 py-1 bg-green-600 text-white rounded-md text-sm tracking-wide"
                          >
                            {loading ? "Uploading" : "Upload Template"}
                          </button>
                        </HStack>
                      </div>
                    )}
                    <div className="mt-4">
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Project Name
                        </label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="text-red-500 text-sm mt-1">
                            {formik.errors.name as any}
                          </div>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Project Description
                        </label>
                        <TextArea
                          id="description"
                          name="description"
                          rows={3}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="text-red-500 text-sm mt-1">
                              {formik.errors.description as any}
                            </div>
                          )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="companyName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Company Name
                        </label>
                        <ComboboxInput
                          value={formik.values.companyName}
                          onChange={(value: string) => {
                            formik.setFieldValue("companyName", value ?? "");
                          }}
                          options={companies ?? []}
                        />
                      </div>
                      {!project && (
                        <div className="mb-4 w-full">
                          <label
                            htmlFor="projectType"
                            className="block text-sm text-left font-medium text-gray-700"
                          >
                            Project Type
                          </label>

                          <Select
                            name="projectType"
                            placeholder="Select Project Type"
                            options={projectType}
                            disabled={project}
                            valueGetter={(value) => value.value}
                            textGetter={(value) => value.label}
                            value={formik.values.projectType}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "projectType",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      )}
                      <HStack className=" gap-4">
                        <div className="mb-4 flex-1">
                          <label
                            htmlFor="startDate"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Start Date
                          </label>
                          <Input
                            type="date"
                            name="startDate"
                            id="startDate"
                            value={formik.values.startDate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.startDate &&
                            formik.errors.startDate && (
                              <div className="text-red-500 text-sm mt-1">
                                {formik.errors.startDate as any}
                              </div>
                            )}
                        </div>
                        <div className="mb-4 flex-1">
                          <label
                            htmlFor="endDate"
                            className="block text-sm font-medium text-gray-700"
                          >
                            End Date
                          </label>
                          <Input
                            type="date"
                            name="endDate"
                            id="endDate"
                            value={formik.values.endDate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </HStack>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          // type="submit"
                          onClick={(e) => formik.handleSubmit()}
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                        >
                          {loading ? (
                            <HStack className="items-center">
                              <p
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please wait
                              </p>
                              <Icon
                                className="animate-spin ml-2"
                                icon="lucide:loader-2"
                                width={18}
                              />
                            </HStack>
                          ) : project ? (
                            "Update Project"
                          ) : (
                            "Create Project"
                          )}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => {
                            handleClickForTracking(
                              AnalyticsConstant.create_project_cancel_button_modal
                            );
                            onClose();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateProjectModal;
