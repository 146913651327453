import { RecurringConfig } from "./SideBarAddTaskPanel";
import { StatesModel } from "./UserStateChange";

export interface TaskExtractModel {
  creatorId: string;
  projectType: string;
  states: StatesModel[];
  groupName: string;
  isActive?: boolean;
  parentTaskId: string;
  isRecurring: boolean;
  isGroupTask: boolean;
  recurringConfig: RecurringConfig[];
  taskType: "" | "INDIVIDUAL";
  orgHierarchy: string[];
  description: string;
  projectDescription: string;
  endDate?: string;
  id: string;
  name: string;
  priority: string;
  isRead: string;
  projectId: string;
  projectName: string;
  companyName: string;
  projectSpecificStatusId?: string | null;
  startDate: string;
  status: string;
  tags: string[];
  userTaskRoles: ExtractUserRole[];
}

export interface ExtractUserRole {
  email: string;
  id: string;
  name: string;
  role: string;
  taskId: string;
  userId: string;
}

const extractMyTasks = (
  projects: any,
  userId: string
): { assignerTask: TaskExtractModel[]; assigneeTask: TaskExtractModel[] } => {
  let assignerTask: any[] = [];
  let assigneeTask: any[] = [];

  if (projects) {
    console.log("projects", projects);
    projects.forEach((project: any) => {
      const assigner = project.tasks
        .filter((task: any) =>
          task.userTaskRoles.find(
            (user: any) => user.userId === userId && user.role === "ASSIGNER"
          )
        )
        .map((task: any) => ({
          ...task,
          projectName: project.name,
          projectId: project.id,
          states: project.states,
          closedStates: project.states.filter(
            (state: any) => state.isFinalState
          ),
        }));
      const assignee = project.tasks
        .filter((task: any) =>
          task.userTaskRoles.find(
            (user: any) => user.userId === userId && user.role === "ASSIGNEE"
          )
        )
        .map((task: any) => ({
          ...task,
          projectName: project.name,
          projectId: project.id,
          states: project.states,
          closedStates: project.states.filter(
            (state: any) => state.isFinalState
          ),
        }));

      if (assigner) assignerTask = [...assignerTask, ...assigner];
      if (assignee) assigneeTask = [...assigneeTask, ...assignee];
    });
  }
  return { assignerTask, assigneeTask };
};

export default extractMyTasks;
