import { useRef } from "react";
import { VStack } from "../../shared/utils";
import { formatDisplayDate } from "../../utils/date";
import { Building2, Calendar, Clock } from "lucide-react";
import { CustomSelect } from "../myTask/UserStateChange";
import { HStack } from "../../shared/utils";
import { limitString } from "../../utils/string";
import { UserUpdateSelectComponent } from "../myTask/UserUpdate";

export const tableEntryStyle = "text-left font-medium text-xs2 text-[#464E5F]";
export const tableCellStyle = { paddingTop: "8px", lineHeight: "20px" };
export const tableFirstColStyle =
  "text-left font-semibold text-xs2 text-[#464E5F]";

export function TaskNameRender(data: any) {
  return (
    <HStack className="items-center gap-4">
      <div
        className={`flex flex-col text-sm text-left pt-2 items-center justify-center cursor-pointer`}
      >
        <div className="flex flex-col">
          <p className="text-left font-semibold items-center justify-center align-middle text-xs2 text-[#464E5F]">
            {data.value[0].toUpperCase() + data.value.slice(1)}
          </p>
          {data.data.isGroup && (
            <span className="text-left font-medium items-center justify-center align-middle text-xxs text-[#464E5F]">
              {data.data.name}
            </span>
          )}
        </div>
      </div>
      {data.data.isRecurring && (
        <Clock className="w-4 h-4 mt-2 text-gray-500" />
      )}
    </HStack>
  );
}

export function StartDate(task: any) {
  return (
    <div
      className={`py-2 whitespace-nowrap ${
        task.data.isRead ? "font-normal" : "font-bold"
      }`}
    >
      {task.value ? (
        <div className="flex items-center text-sm text-gray-500">
          <Calendar className="h-4 w-4 mr-2" />
          {formatDisplayDate(task.value ?? "")}
        </div>
      ) : (
        <div>-</div>
      )}
    </div>
  );
}

export function ProjectName(task: any) {
  return (
    <div
      className={`py-2 whitespace-nowrap ${
        task.data.isRead ? "font-normal" : "font-bold"
      }`}
    >
      {task.value ? (
        <div className="flex items-center text-sm text-gray-500">
          {task.value}
        </div>
      ) : (
        <div>-</div>
      )}
    </div>
  );
}

export function TaskDescriptionRender(task: any) {
  return (
    <div
      className={`py-2 whitespace-nowrap ${
        task.data.isRead ? "font-normal" : "font-semibold"
      }`}
    >
      {task.value ? (
        <div className="flex items-center text-sm text-gray-500 gap-2">
          <span> {task.value}</span>
        </div>
      ) : (
        <div>-</div>
      )}
    </div>
  );
}

export function TaskTagRender(task: any) {
  return (
    <div className="py-2 whitespace">
      {task.value ? (
        <div className="flex items-center flex-wrap text-sm text-gray-500 gap-2">
          {task.value.map((ele: any) => (
            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
              <span> {ele}</span>
            </span>
          ))}
        </div>
      ) : (
        <div>-</div>
      )}
    </div>
  );
}

export function ManagerName(task: any) {
  return task.value === "-" ? (
    <div></div>
  ) : (
    // <div>
      <UserUpdateSelectComponent users={task.value} hideToolTip={true} onChange={() => {}} />
    // </div>
  );
}

export function TaskDescription(task: any) {
  return (
    <div
      className={`py-2 whitespace-nowrap ${
        task.data.isRead ? "font-normal" : "font-bold"
      }`}
    >
      <div className="flex items-center text-sm text-gray-500">
        {task.value}
      </div>
    </div>
  );
}

export function TaskStatus(task: any) {
  return (
    <CustomSelect
      state={task.states}
      isAssignee={false}
      data={task?.value ?? ""}
      onChange={(data: any) => {}}
    />
  );
}

export const getDates = (deadline: any) => {
  const date1 = new Date(deadline);
  const date2 = new Date();
  const diffInDays = Math.ceil(
    (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)
  );

  const result = diffInDays <= 2 ? diffInDays : null;
  return result;
};

export function EndDate(task: any) {
  return (
    <div
      className={`py-2 whitespace-nowrap ${
        task.data.isRead ? "font-normal" : "font-bold"
      }`}
    >
      {task.value ? (
        <VStack>
          <div className="flex items-center text-xs2 text-gray-500">
            <Calendar className="h-4 w-4 mr-2" />
            {formatDisplayDate(task.value ?? "")}
          </div>
          {task.data.statusLabel.toLowerCase() !== "closed" &&
            getDates(task.value) !== null && (
              <span
                className={`inline-flex w-fit items-center gap-x-1.5 rounded-md  px-2 py-0.5 text-[9px] leading-0 font-medium ${
                  (getDates(task.value) ?? 0) > 0
                    ? "text-yellow-600 bg-yellow-50"
                    : "text-red-600 bg-red-50"
                }`}
              >
                <svg
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                  className={`h-1.5 w-1.5 ${
                    (getDates(task.value) ?? 0) > 0
                      ? "fill-yellow-600"
                      : "fill-red-500"
                  }`}
                >
                  <circle r={3} cx={3} cy={3} />
                </svg>
                {(getDates(task.value) ?? 0) > 0
                  ? `${getDates(task.value)} Days Left`
                  : (getDates(task.value) ?? 0) === 0
                  ? `Due Today`
                  : `${Math.abs(getDates(task.value) ?? 0)} Days Overdue`}
              </span>
            )}
        </VStack>
      ) : (
        <div>-</div>
      )}
    </div>
  );
}

export function EmptyTable(data: any) {
  return (
    <VStack>
      <p>{data.header}</p>
      <p>{data.subheader}</p>
    </VStack>
  );
}

export function LoadingOverlayComponent(data: any) {
  return <p>{data.loadingText}</p>;
}

export function RenderCount(sampleData: any) {
  const renderCount = useRef(1);
  return (
    <>
      <b>{renderCount.current++}</b>
      {sampleData.value}
    </>
  );
}
