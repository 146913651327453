import React, { useState, useRef, useEffect } from "react";
import StateBadge from "../dashboard/StateBadge";
import ReactDOM from "react-dom";

export interface StatesModel {
  id: string;
  name: string;
  order: string;
  projectType: string;
  bgColor: string;
  textColor: string;
  isFinalState: boolean;
}

export interface SelectStatusModel {
  id: string;
  label: string;
  textColor: string;
  color: string;
  disabled: boolean;
}
const MAX_MENU_HEIGHT = 100;
const AVG_OPTION_HEIGHT = 40;

export function CustomSelect({
  state,
  data,
  isPreview = false,
  onChange,
  isAssignee = false,
}: {
  state: StatesModel[];
  data: string;
  isPreview?: boolean;
  onChange: any;
  isAssignee?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<StatesModel | undefined>(
    state.find((ele) => data === ele.name)
  );
  const contextRef = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const [dropUp, setDropUp] = useState(false);
  useEffect(() => {
    if (dropdownOpen) determineDropUp();
    document.addEventListener("mousedown", determineDropUp);

    return () => {
      document.removeEventListener("mousedown", determineDropUp);
    };
  }, [dropdownOpen]);
  const selectInst = useRef<HTMLDivElement>(null);
  const determineDropUp = () => {
    const options = [];
    const node = ReactDOM.findDOMNode(selectInst.current) as any;
    const node1 = ReactDOM.findDOMNode(contextRef.current) as any;
    if (!node) return;
    const windowHeight = window.innerHeight;
    const menuHeight = Math.min(
      MAX_MENU_HEIGHT,
      options.length * AVG_OPTION_HEIGHT
    );

    const dropMenuHeight = selectInst.current?.clientHeight || 0;
    const instOffsetWithMenu = node.getBoundingClientRect().bottom + menuHeight;

    setDropUp(windowHeight - instOffsetWithMenu > dropMenuHeight);
  };
  useEffect(() => {
    setSelectedOption(state.find((ele) => data === ele.name));
  }, [data]);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className={`flex flex-col`} ref={contextRef}>
      <div
        className="inline-flex text-xs text-center rounded-lg peer"
        onClick={() => {
          if (!isPreview) setDropDownOpen((state) => !state);
        }}
      >
        {selectedOption && (
          <StateBadge
            onClick={() => {}}
            isPreview={isPreview}
            selectedOption={selectedOption}
          />
        )}
      </div>

      {dropdownOpen && (
        <div
          id="DropDownList"
          className="origin-top-right z-50 absolute left- top-48 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
        >
          <div className="">
            {state
              .sort(
                (a: StatesModel, b: StatesModel) =>
                  Number(a.order) - Number(b.order)
              )
              .map(
                (ele) =>
                  ({
                    id: ele.id,
                    label: ele.name,
                    color: ele.bgColor,
                    textColor: ele.textColor,
                  } as SelectStatusModel)
              )
              .map((option) => {
                const colorData = `${
                  option.id === selectedOption?.id ? "bg-gray-100" : ""
                } group flex items-center w-full px-4 py-2 text-sm font-semibold hover:bg-gray-300 hover:text-blue-800`;

                return (
                  <button
                    style={{
                      background: option.color,
                      color: option.textColor,
                    }}
                    key={option.id}
                    onClick={(e: any) => {
                      if (!option.disabled) {
                        e.preventDefault();

                        setDropDownOpen((state) => !state);
                      }
                      handleOptionClick(option);
                    }}
                    className={colorData}
                  >
                    {option.label}
                  </button>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
