import { init } from "@amplitude/analytics-browser";
import { logGAEvent } from "./GAanalytics";

export const AmplitudeInit = () => {
  if (process.env.REACT_APP_AMPLITUDE_KEY)
    init(process.env.REACT_APP_AMPLITUDE_KEY || "");
};

export function handleEventForTracking({
  eventName,
  eventType = "user_interaction",
  success = true,
}: {
  eventName: string;
  eventType?: "navigation" | "custom" | "hover" | "API" | "user_interaction";
  success?: boolean;
}) {
  const action = eventType === "API" ? "api_call" : "button_click";
  logGAEvent(eventType, action, eventName);
}

export function handleClickForTracking(eventName: any) {
  logGAEvent("user_interaction", "button_click", eventName);
}

export function handlePageLoadForTracking(event: any) {}
