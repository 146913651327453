import React, { useState, DragEvent, useEffect, useMemo } from "react";
import { Calendar, Users } from "lucide-react";
import { TaskExtractModel } from "../myTask/useExtractMyTask";
import { useUpdateTask } from "../../queries/admin";
import { limitString } from "../../utils/string";
import Tooltip from "../../shared/Tooltip";

interface KanbanStatesModel {
  bgColor: string;
  id: string;
  isFinalState: boolean;
  name: string;
  order: string;
  projectType: string;
  textColor: string;
}

interface KanbanColumn {
  title: string;
  items: TaskExtractModel[];
  state: KanbanStatesModel;
}

interface KanbanColumns {
  [key: string]: KanbanColumn;
}

interface KanbanBoardProps {
  allTasks: any[];
  onCardClick: any;
}

const KanbanBoard: React.FC<KanbanBoardProps> = ({ allTasks, onCardClick }) => {
  const kanbanStates: KanbanStatesModel[] = allTasks[0]?.states ?? [];

  const [updatedFilters, setUploadFilters] = useState<{
    projectIds: string[];
    priorities: string[];
    statuses: string[];
  }>({
    projectIds: [],
    priorities: [],
    statuses: [],
  });

  const tasks: any[] = useMemo(() => {
    const dataSet = allTasks;
    return (dataSet || [])
      .filter((ele: any) => {
        if (updatedFilters.projectIds.length === 0) return true;
        return updatedFilters.projectIds.includes(ele.projectName);
      })
      .filter((ele: any) => {
        if (updatedFilters.priorities.length === 0) return true;
        return updatedFilters.priorities.includes(ele.priority);
      })
      .filter((ele: any) => {
        if (updatedFilters.statuses.length === 0) return true;
        return updatedFilters.statuses.includes(ele.status);
      })
      .sort(
        (a: any, b: any) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );
  }, [allTasks, updatedFilters]);

  const initializeColumns = () => {
    const columns: KanbanColumns = {};

    kanbanStates
      .sort((a, b) => Number(a.order) - Number(b.order))
      .forEach((state) => {
        columns[state.name] = {
          title: state.name,
          items: [],
          state: state,
        };
      });

    tasks.forEach((task) => {
      if (columns[task.status]) {
        columns[task.status].items.push(task);
      }
    });

    return columns;
  };

  const [columns, setColumns] = useState<KanbanColumns>(initializeColumns());
  const { mutate: updateTask } = useUpdateTask();

  useEffect(() => {
    setColumns(initializeColumns());
  }, [tasks, kanbanStates]);

  const handleDragStart = (
    e: DragEvent<HTMLDivElement>,
    task: TaskExtractModel
  ) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(task));
    e.currentTarget.classList.add("opacity-50");
  };

  const handleDragEnd = (e: DragEvent<HTMLDivElement>) => {
    e.currentTarget.classList.remove("opacity-50");
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.classList.add("bg-gray-100");

    // Get the container element
    const container = document.querySelector(".flex.gap-4.overflow-x-auto");
    if (!container) return;

    // Get container dimensions and scroll position
    const containerRect = container.getBoundingClientRect();
    const scrollSpeed = 15;

    // Calculate distance from right edge
    const distanceFromRight = containerRect.right - e.clientX;

    // If within 50px of right edge, scroll right
    if (distanceFromRight < 50) {
      container.scrollLeft += scrollSpeed;
    }
    // If within 50px of left edge, scroll left
    else if (e.clientX - containerRect.left < 50) {
      container.scrollLeft -= scrollSpeed;
    }
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.currentTarget.classList.remove("bg-gray-100");
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>, columnId: string) => {
    e.preventDefault();
    e.currentTarget.classList.remove("bg-gray-100");

    const taskData = e.dataTransfer.getData("text/plain");
    if (!taskData) return;

    const task: TaskExtractModel = JSON.parse(taskData);
    if (task.status === columnId) return;

    if (task.groupName && !task.parentTaskId) {
      const shouldUpdate = window.confirm(
        "Do you want to update all subtasks in this group?"
      );
      updateTask({
        projectId: task.projectId ?? "",
        taskId: task.id ?? "",
        status: columnId,
        updateSubTasks: shouldUpdate,
      });
    } else {
      updateTask({
        projectId: task.projectId ?? "",
        taskId: task.id ?? "",
        status: columnId,
        updateSubTasks: false,
      });
    }
  };

  const getPriorityColor = (priority: string): string => {
    switch (priority.toLowerCase()) {
      case "high":
        return "bg-red-100 text-red-800";
      case "medium":
        return "bg-yellow-100 text-yellow-800";
      case "low":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div className="p-4 bg-white min-h-screen">
      <div className="flex gap-4 overflow-x-auto min-h-[calc(100vh-2rem)]">
        {Object.entries(columns).map(([columnId, column]) => (
          <div
            key={columnId}
            className="flex-1 min-w-64"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, columnId)}
          >
            <div className="bg-gray-50 rounded-lg shadow-sm h-full border border-gray-200">
              <div
                style={{
                  background: column.state.bgColor,
                  color: column.state.textColor,
                }}
                className="border-b flex-1 border-gray-200 rounded-t-md p-4"
              >
                <div className="flex justify-between w-full items-center flex-row">
                  <Tooltip position="top-10 whitespace-nowrap" text={column.title}>
                    <h3 className="font-semibold text-sm whitespace-nowrap">
                      {limitString(column.title, 16)}
                    </h3>
                  </Tooltip>

                  <p className="ml-2 text-sm bg-gray-100 text-gray-900 font-bold rounded-full p-1 px-3">
                    {column.items.length}
                  </p>
                </div>
              </div>

              <div className="p-2 bg-gray-50 min-h-[200px]">
                <div className="space-y-2">
                  {column.items.map((task) => (
                    <div
                      draggable
                      onClick={() => {
                        onCardClick({
                          task: task,
                          state: true,
                        });
                      }}
                      onDragStart={(e) => handleDragStart(e, task)}
                      onDragEnd={handleDragEnd}
                      className="bg-white  rounded-lg border border-gray-200 shadow-sm hover:shadow-md 
                                transition-all duration-200 cursor-pointer group p-3"
                    >
                      {/* Header Section with Title and Priority */}
                      <div className="flex justify-between items-start gap-4 mb-1">
                        <h3 className="text-sm font-semibold text-gray-900">
                          {task.name}
                        </h3>
                      </div>

                      {/* Project Name */}
                      {task.description && (
                        <div className="mb-3">
                          {/* <span className="text-sm font-medium text-gray-700">
                            Project:
                          </span> */}
                          <span className="text-xs text-gray-600">
                            {task.description}
                          </span>
                        </div>
                      )}

                      {/* Dates Section */}
                      <div className="flex flex-row gap-2">
                        <div className="flex items-center text-xs text-gray-600">
                          <Calendar className="w-4 h-4 mr-2" />
                          <span className="font-medium">Due:</span>
                          <span className="ml-2">
                            {task.endDate
                              ? formatDate(task.endDate)
                              : "Ongoing"}
                          </span>
                        </div>
                        <span
                          className={`px-1 py-0.5 rounded-full text-xxs font-medium ${getPriorityColor(
                            task.priority
                          )}`}
                        >
                          {task.priority}
                        </span>
                      </div>

                      {/* Tags Section */}
                      {task.tags?.length > 0 && (
                        <div className="flex flex-wrap gap-2 mt-2">
                          {task.tags.map((tag, index) => (
                            <span
                              key={index}
                              className="text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded-full"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                      )}

                      {/* Group Task Indicator */}
                      {task.isGroupTask && (
                        <div className="flex items-center mt-3 text-sm text-gray-600">
                          <Users className="w-4 h-4 mr-2" />
                          <span>Group Task</span>
                        </div>
                      )}
                    </div>
                    // <div
                    //   key={task.id}
                    //   draggable
                    //   onDragStart={(e) => handleDragStart(e, task)}
                    //   onDragEnd={handleDragEnd}
                    //   className="bg-white p-3 rounded-lg border border-gray-200 shadow-sm
                    //            hover:shadow-md transition-shadow cursor-move group"
                    // >
                    //   <div className="flex justify-between items-start">
                    //     <div className="flex-1">
                    //       <h4 className="font-medium text-gray-800">
                    //         {task.name}
                    //       </h4>

                    //       <div className="mt-2 space-y-2">
                    //         <div className="flex items-center text-xs text-gray-600">
                    //           <Calendar className="w-3 h-3 mr-1" />
                    //           {formatDate(task.startDate)} -{" "}
                    //           {task.endDate
                    //             ? formatDate(task.endDate)
                    //             : "Ongoing"}
                    //         </div>
                    //         {task.projectName && (
                    //           <div className="text-xs text-gray-600">
                    //             Project: {task.projectName}
                    //           </div>
                    //         )}
                    //         {task.tags?.length > 0 && (
                    //           <div className="flex flex-wrap gap-1">
                    //             {task.tags.map((tag, index) => (
                    //               <span
                    //                 key={index}
                    //                 className="text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded-full"
                    //               >
                    //                 {tag}
                    //               </span>
                    //             ))}
                    //           </div>
                    //         )}
                    //       </div>
                    //     </div>
                    //     <div className="flex items-center gap-2">
                    //       <span
                    //         className={`text-xs px-2 py-1 rounded-full ${getPriorityColor(
                    //           task.priority
                    //         )}`}
                    //       >
                    //         {task.priority}
                    //       </span>
                    //       {task.isGroupTask && (
                    //         <span className="flex items-center text-xs text-gray-600">
                    //           <Users className="w-3 h-3 mr-1" />
                    //           Group Task
                    //         </span>
                    //       )}
                    //     </div>
                    //   </div>
                    // </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KanbanBoard;
