import React, { useState, useEffect, useRef } from "react";
import { Menu } from "@headlessui/react";
import {
  ChevronLeft,
  ChevronRight,
  MoreVertical,
  Calendar,
} from "lucide-react";
import { formatDisplayDate } from "../../utils/date";
import ScheduledTaskComponent from "./ScheduledTask";
import TaskCard from "./TaskCard";
import { getDates } from "../aggrid/AgComponent";
import { StatesModel } from "../myTask/UserStateChange";

interface Task {
  id: string;
  states: StatesModel[];
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  status: string;
  priority: string;
  isActive: boolean;
}

interface CalendarDay {
  date: Date;
  isCurrentMonth: boolean;
}

interface CalendarTaskProps {
  tasks: Task[];
  selectedDate: Date;
  setSelectedDate: (id: Date) => void;
  setShowTaskOnCalender: (id: string) => void;
}

const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month + 1, 0).getDate();
};

const getMonthData = (year: number, month: number): CalendarDay[] => {
  const firstDay = new Date(Date.UTC(year, month, 1, 5, 30)).getUTCDay();
  const daysInMonth = getDaysInMonth(year, month);
  const days: CalendarDay[] = [];

  for (let i = 0; i < firstDay; i++) {
    days.push({
      date: new Date(Date.UTC(year, month, -firstDay + i + 1, 5, 30)),
      isCurrentMonth: false,
    });
  }

  for (let i = 1; i <= daysInMonth; i++) {
    days.push({
      date: new Date(Date.UTC(year, month, i, 5, 30)),
      isCurrentMonth: true,
    });
  }

  while (days.length % 7 !== 0) {
    days.push({
      date: new Date(
        Date.UTC(
          year,
          month + 1,
          days.length - daysInMonth - firstDay + 1,
          5,
          30
        )
      ),
      isCurrentMonth: false,
    });
  }

  return days;
};

const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const formatDate = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

const CalendarTask: React.FC<CalendarTaskProps> = ({
  tasks,
  selectedDate,
  setSelectedDate,
  setShowTaskOnCalender,
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [showUpcoming, setShowUpcoming] = useState<boolean>(false);
  const [showOverDue, setShowOverDue] = useState<boolean>(false);
  const [showAllInRange, setShowAllInRange] = useState<boolean>(false);
  const [monthData, setMonthData] = useState<CalendarDay[]>([]);

  useEffect(() => {
    setMonthData(
      getMonthData(currentDate.getFullYear(), currentDate.getMonth())
    );
  }, [currentDate]);

  const goToPreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const goToNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
  };

  const isTaskRelevant = (task: Task): boolean => {
    const taskStartDate = new Date(task.startDate);
    const taskEndDate = new Date(task.endDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (
      taskStartDate.toDateString() === today.toDateString() ||
      taskEndDate.toDateString() === today.toDateString()
    ) {
      return true;
    }

    if (showUpcoming) {
      const twoDaysFromNow = new Date(
        selectedDate.getTime() + 2 * 24 * 60 * 60 * 1000
      );
      const status =
        (taskStartDate >= selectedDate && taskStartDate <= twoDaysFromNow) ||
        (taskEndDate >= selectedDate && taskEndDate <= twoDaysFromNow);
      if (status) return status;
    }

    if (showAllInRange) {
      const status =
        taskStartDate <= selectedDate && taskEndDate >= selectedDate;
      if (status) return status;
    }
    if (
      showOverDue &&
      task.status.toLowerCase() !== "closed" &&
      getDates(task.endDate) !== null
    ) {
      return true;
    }

    return (
      taskStartDate.toDateString() === selectedDate.toDateString() ||
      taskEndDate.toDateString() === selectedDate.toDateString()
    );
  };

  const relevantTasks = tasks.filter(isTaskRelevant);

  const [showCalender, setShowCalender] = useState(false);
  const contentRef = useRef<any>(null);
  return (
    <div className="bg-slate-50 px-4 pt-4 rounded-xl shadow-box border-gray-300 border ">
      <div
        className={`flex h-8 items-center ${
          !showCalender ? "justify-between" : "justify-between"
        }`}
      >
        <h2 className="text-sm font-semibold text-gray-900">
          {!showCalender
            ? "Scheduled Task"
            : currentDate.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
        </h2>
      </div>

      <section className="pt-8 px-6 -mx-4 pb-4 rounded-b-xl bg-white">
        <ScheduledTaskComponent
          showCalender={showCalender}
          showUpcoming={showUpcoming}
          setShowUpcoming={setShowUpcoming}
          showAllInRange={showAllInRange}
          setShowAllInRange={setShowAllInRange}
          selectedDate={formatDisplayDate(selectedDate)}
          showOverDue={showOverDue}
          setShowOverDue={setShowOverDue}
        />
        {relevantTasks.length === 0 && (
          <div className="mt-4">
            <p>Your scheduled tasks will be displayed here.</p>
          </div>
        )}
        <ol className="mt-4 space-y-4 text-sm leading-6 h-[480px] overflow-auto text-gray-500">
          {relevantTasks.map((task) => {
            const isToday =
              new Date(task.startDate).toDateString() ===
                new Date().toDateString() ||
              new Date(task.endDate).toDateString() ===
                new Date().toDateString();
            return (
              <TaskCard
                task={task}
                setShowTaskOnCalender={setShowTaskOnCalender}
              />
            );
          })}
        </ol>
      </section>
    </div>
  );
};

export default CalendarTask;
