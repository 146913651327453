import { HStack } from "../../shared/utils";
import HomePageAssignedToMeTask from "./HomePageAssignedToMe";
import HomePageAssignedTask from "./HomePageAssignedTask";
import { useAuthStore } from "../../store/useAuthStore";
import { useGetOverview } from "../../queries/admin";
import extractMyTasks from "../myTask/useExtractMyTask";

export default function HomePage() {
  const { data: userData } = useGetOverview();
  const { id: userId } = useAuthStore();

  const myTasks = extractMyTasks(userData, userId ?? "");

  return (
    <div className="flex flex-col gap-8">
      <HStack className="flex flex-col sm:flex-row gap-8">
        <HomePageAssignedToMeTask tasks={myTasks?.assigneeTask ?? []} />
        <HomePageAssignedTask task={myTasks?.assignerTask ?? []} />
      </HStack>
    </div>
  );
}
