import { useState } from "react";
import { useAuthStore } from "../../store/useAuthStore";
import { useGetOverview } from "../../queries/admin";
import useExtractTasks from "./ExtractTaskHook";
import MyTaskEmptyScreen from "./MyTaskEmptyScreen";
import AGGridSimpleTable from "../aggrid/AGGridSimpleTable";
import { TableId } from "../project/TableIdConstants";
import useIsMobile from "../../utils/detectDevice";

const MyTaskPage = () => {
  const { data: userData } = useGetOverview();
  const allTask = useExtractTasks(userData);
  const { isMobile } = useIsMobile();
  const platForm = isMobile ? "mobile" : "web";
  return (
    <div className="-mx-2">
      <AGGridSimpleTable
        tasks={allTask}
        tableId={TableId.myTaskTable + platForm}
      />
    </div>
  );
};

export default MyTaskPage;
