/* eslint-disable react/no-find-dom-node */

/* eslint-disable no-undef */
import { Icon } from "@iconify/react";
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

export type Action = {
  data?: any;
  name: string;
  icon?: any;
  disabled?: boolean;
};

const MAX_MENU_HEIGHT = 100;
const AVG_OPTION_HEIGHT = 40;

export function CTADropdown({
  actions,
  onAction,
  className = "items-end",
  dropdownClassName = "items-end",
  render = () => (
    <Icon
      icon="carbon:overflow-menu-horizontal"
      className="text-gray-400 placeholderLoader rounded hover:bg-gray-300"
      height={20}
    />
  ),
}: {
  className?: string;
  dropdownClassName?: string;
  actions: Action[];
  onAction: (action: Action) => void;
  render?: () => JSX.Element;
}) {
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const contextRef = useRef<HTMLDivElement>(null);
  const [dropUp, setDropUp] = useState(false);
  const selectInst = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dropdownOpen) determineDropUp();
    document.addEventListener("mousedown", determineDropUp);

    return () => {
      document.removeEventListener("mousedown", determineDropUp);
    };
  }, [dropdownOpen]);

  const determineDropUp = () => {
    const options = actions || [];
    const node = ReactDOM.findDOMNode(selectInst.current) as any;
    const node1 = ReactDOM.findDOMNode(contextRef.current) as any;
    if (!node) return;
    const windowHeight = window.innerHeight;
    const menuHeight = Math.min(
      MAX_MENU_HEIGHT,
      options.length * AVG_OPTION_HEIGHT
    );
    const dropMenuHeight = selectInst.current?.clientHeight || 0;
    const instOffsetWithMenu = node.getBoundingClientRect().bottom + menuHeight;

    setDropUp(windowHeight - instOffsetWithMenu > dropMenuHeight);

  };

  const [dropPosition, setDropPosition] = useState("");

  useEffect(() => {
    if (dropUp === true) {
      setDropPosition("top-auto bottom-full");
    }
  }, [dropUp]);

  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);
  const RenderElement = render;
  return actions ? (
    <div className={`flex flex-col ${className} `} ref={contextRef}>
      <button
        className="inline-flex text-xs text-center rounded-lg peer"
        type="button"
        onClick={() => setDropDownOpen((state) => !state)}
      >
        <RenderElement />
      </button>
      {dropdownOpen && (
        <div
          id="dropdown"
          className={`
          absolute z-10 mt-8  bg-white border divide-y divide-gray-100 rounded shadow ${dropdownClassName}`}
        >
          <p className="px-4 py-1 text-sm font-bold text-stone-300">
            More actions
          </p>
          {actions?.map((action, index) => (
            <a
              key={index}
              className={`px-4 flex flex-row gap-2 text-xs text-center items-center py-2 hover:bg-slate-50  ${
                action.disabled
                  ? "text-gray-300 cursor-not-allowed "
                  : "hover:text-red-500 cursor-pointer"
              } `}
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                if (!action.disabled) {
                  e.preventDefault();
                  onAction(action);
                  setDropDownOpen((state) => !state);
                }
              }}
            >
              <span>{action.icon}</span>
              <span>{action.name}</span>
            </a>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div></div>
  );
}
