import { useLocation, useNavigate } from "react-router-dom";
import { classNames, limitString } from "../utils/string";
import { HStack, VStack } from "../shared/utils";
import { useAuthStore } from "../store/useAuthStore";
import Avatar from "../pages/aggrid/Avatar";
import { handleClickForTracking } from "../analytics";
import { AnalyticsConstant } from "../AnalyticsContant";
import { useState } from "react";
import LogoutModal from "../modal/LogoutModal";

interface Navigation {
  name: string;
  href: string;
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  current: boolean;
}
interface Resources {
  id: number;
  name: string;
  href: string;
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  initial: string;
  current: boolean;
}
export default function NavLayout({
  navigation,
  resources,
  closeSideBar,
}: {
  navigation: Navigation[];
  resources: Resources[];
  closeSideBar: () => void;
}) {
  const [dialogLogout, setDialogLogout] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { role, name, email } = useAuthStore();
  return (
    <nav className="flex flex-1 flex-col h-screen ">
      <ul className="flex flex-1 flex-col gap-y-7 ">
        <li>
          <ul className="-mx-2 space-y-1">
            {navigation.map((item, index) => (
              <li key={item.name + index} className="cursor-pointer">
                <button
                  onClick={() => {
                    handleClickForTracking(
                      AnalyticsConstant.sidebar_navigation
                    );
                    navigate(item.href);
                    closeSideBar();
                  }}
                  className={classNames(
                    item.href === pathname
                      ? "bg-gray-50 text-indigo-600"
                      : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                    "group flex gap-x-3 w-full rounded-md p-2 text-sm font-semibold leading-6"
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.href === pathname
                        ? "text-indigo-600"
                        : "text-gray-400 group-hover:text-indigo-600",
                      "h-6 w-6 shrink-0"
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <div className="text-sm font-semibold leading-6 text-gray-400">
            All Projects
          </div>
          <ul className="-mx-2 mt-2 space-y-1">
            {resources.map((resource, index) => (
              <li key={resource.name + index} className="cursor-pointer">
                <button
                  onClick={() => {
                    handleClickForTracking(
                      AnalyticsConstant.sidebar_project_navigation
                    );
                    navigate(resource.href);
                    closeSideBar();
                  }}
                  className={classNames(
                    resource.href === pathname
                      ? "bg-gray-50 text-indigo-600"
                      : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                    "group flex gap-x-3 w-full rounded-md p-2 text-sm font-semibold leading-6 justify-between"
                  )}
                >
                  <HStack className="items-center gap-3">
                    <span
                      className={classNames(
                        resource.href === pathname
                          ? "border-indigo-600 text-indigo-600"
                          : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                        "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                      )}
                    >
                      {resource.initial}
                    </span>
                    <span className="truncate">
                      {limitString(resource.name, 20)}
                    </span>
                  </HStack>
                  <resource.icon
                    className={classNames(
                      "text-gray-400 group-hover:text-indigo-600",
                      "h-6 w-6 shrink-0"
                    )}
                    aria-hidden="true"
                  />
                </button>
              </li>
            ))}
          </ul>
        </li>
      </ul>

      <div className="divide-x divide-gray-900/5 bg-gray-50 -mx-5 -mb-4">
        <div className="group relative flex gap-x-2 items-center rounded-lg p-4 hover:bg-gray-50">
          <div
            onClick={() => {
              navigate("/settings");
              closeSideBar();
            }}
            className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
          >
            <Avatar name={name || ""} />
          </div>
          <VStack className="w-full">
            <HStack
              onClick={() => {
                navigate("/settings");
                closeSideBar();
              }}
              className="justify-between items-center w-full"
            >
              <a className="font-semibold text-gray-900 flex-1 text-sm">
                {name}

                {/* <span className="absolute inset-0" /> */}
              </a>

              <a className="ml-1 text-xs text-gray-500 text-ellipsis overflow-clip">
                {role}
              </a>
            </HStack>

            <HStack className="justify-between items-center w-full">
              <a
                onClick={() => {
                  navigate("/settings");
                  closeSideBar();
                }}
                className="font-normal text-gray-500 flex-1 text-sm"
              >
                {email}

                {/* <span className="absolute inset-0" /> */}
              </a>
              {dialogLogout && (
                <LogoutModal
                  isOpen={dialogLogout}
                  onClose={() => {
                    setDialogLogout(false);
                  }}
                />
              )}
              <a
                onClick={() => {
                  setDialogLogout(true);
                }}
                className="font-medium text-right text-gray-500 flex-1 ml-4 text-xs underline"
              >
                Log out
                {/* <span className="absolute bg-red-800" /> */}
              </a>
            </HStack>
          </VStack>
        </div>
      </div>
    </nav>
  );
}
