import api from "../queries/api";
import { QueryFunctionContext } from "react-query";
import { UserTaskRoleModel } from "../types/TaskModel";

export interface ProjectModel {
  name: string;
  description: string;
  projectId?: string;
}

export interface NotificationModel {
  entityType: string;
  eventType: string;
  isEnabled: boolean;
  projectId: string;
  id: string;
  reminderFrequency?:
    | "ONE_DAY_BEFORE"
    | "TWO_DAYS_BEFORE"
    | "THREE_DAYS_BEFORE"
    | "ONE_WEEK_BEFORE"
    | "TWO_WEEK_BEFORE";
}
export interface CreateUserProjectRoleModel {
  id?: string;
  email: string;
  role: string;
  projectId?: string;
}

export interface UserProjectRoles {
  email: string;
  id: string;
  name: string;
  projectId: string;
  role: string;
  userId: string;
}

export async function getUserProjectRoles(
  context: QueryFunctionContext
): Promise<UserProjectRoles[]> {
  const projectId = context.queryKey[1];
  return api
    .get(`project/userProjectRoles?projectId=${projectId}`)
    .then((res) => res.data.data);
}

export async function getUserProfile(): Promise<any> {
  return api.get(`auth/userProfile`).then((res) => res.data.data);
}
export async function updateUserProfile(data: {
  userId: string;
  name: string;
}): Promise<any> {
  return api.put(`auth/userProfile`, data).then((res) => res.data.data);
}

export async function createUserProjectRole(
  projectRole: CreateUserProjectRoleModel
): Promise<any> {
  return api
    .post(`project/userProjectRoles`, projectRole)
    .then((res) => res.data);
}

export async function updateUserProjectRole(
  updatedUser: CreateUserProjectRoleModel
): Promise<any> {
  return api
    .put(`project/userProjectRoles`, updatedUser)
    .then((res) => res.data);
}

export async function getNotificationSetting(
  context: QueryFunctionContext
): Promise<NotificationModel[]> {
  const projectId = context.queryKey[1];
  return api
    .get(`project/notifications?projectId=${projectId}`)
    .then((res) => res.data.data);
}

export async function updateNotificationSetting(
  updatedUser: NotificationModel
): Promise<any> {
  return api.put(`project/notifications/`, updatedUser).then((res) => res.data);
}

export async function deleteUserProjectRole({
  projectId,
  userId,
}: {
  projectId: any;
  userId: any;
}): Promise<any> {

  return api
    .delete(`project/userProjectRoles?projectId=${projectId}&userId=${userId}`)
    .then((res) => res.data);
}

export async function getUserTaskRoles(
  context: QueryFunctionContext
): Promise<UserProjectRoles[]> {
  const taskId = context.queryKey[1];
  return api
    .get(`project/task/userTaskRoles?taskId=${taskId}`)
    .then((res) => res.data.data);
}

export async function createUserTaskRole(
  userTaskRole: UserTaskRoleModel
): Promise<any> {
  return api
    .post(`project/task/userTaskRoles`, userTaskRole)
    .then((res) => res.data);
}

export async function updateUserTaskRole(
  userTaskRole: UserTaskRoleModel
): Promise<any> {
  return api
    .put(`project/task/userTaskRoles`, userTaskRole)
    .then((res) => res.data);
}

export async function deleteUserTaskRole({
  projectId,
  email,
  taskId,
}: {
  projectId: string;
  email: string;
  taskId: string;
}): Promise<any> {
  return api
    .delete(`project/task/userTaskRoles?taskId=${taskId}&email=${email}`)
    .then((res) => res.data);
}
